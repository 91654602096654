/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="report-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Report List</h3>
      <div class="widget">
        <div class="report-content">
          <b-form @reset.prevent="resetForm()">
            <div>
              <div class="font-18 py-3">
                Information
              </div>
              <div class="table-responsive">
                <table class="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>
                        Date of Invoice
                      </th>
                      <th>Party Name</th>
                      <th>GSTIN</th>
                      <th>State</th>
                      <th>Country</th>
                      <th>HSN/SAC code</th>
                      <th>Taxable Value</th>
                      <th>IGST</th>
                      <th>CGST</th>
                      <th>SGST</th>
                      <th>Invoice Value</th>
                      <th>Nature of Services Provided</th>
                      <th>Transaction Date</th>
                      <th>Payment Reference Number</th>
                      <th>Method of Payment/Gateway</th>
                      <th>Amount Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>1/7/2020</td>
                      <td>Party Name</td>
                      <td>23Fasdf</td>
                      <td>Maharashtra</td>
                      <td>India</td>
                      <td>2342</td>
                      <td>34234</td>
                      <td>23423</td>
                      <td>234</td>
                      <td>3423</td>
                      <td>23423</td>
                      <td>services</td>
                      <td>4/8/2020</td>
                      <td>34</td>
                      <td>Method</td>
                      <td>100000</td>
                      <td>
                        <div>
                          <b-button variant="success" type="submit">
                            Export to Excel
                          </b-button>
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-button variant="danger" type="reset" class="">Cancel</b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom
  },
  data() {
    return {
      invoice: {
        submit: "",
        date: "",
        partyName: "",
        gstin: "",
        state: "",
        country: "",
        code: "",
        tax: "",
        igst: "",
        cgst: "",
        sgst: "",
        invoiceValue: "",
        services: "",
        transactionDate: "",
        referenceNo: "",
        gateway: "",
        amount: ""
      }
    };
  },
  methods: {
    resetForm() {
      this.$router.push({ name: "report" });
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.report-section {
  .report-content {
    padding: 20px 20px;
    .table-responsive {
      font-size: 14px;
    }
  }
}
.error-text {
  font-size: 13px;
  color: #dc3545;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
